<template>
    <div class="user-profile">
        <div v-if="Profile !== null" class="icon">
            <img :src="Profile" />
        </div>
        <div v-else class="icon">
            <span>{{ getInitials(Name, Surname, Username) }}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: "Profile",

    props: {
        Name: String,
        Surname: String,
        Username: String,
        Profile: String
    },

    methods: {
        getInitials: function (Name, Surname, Username) {
            if (Name !== undefined && Surname !== undefined)
                return Name[0] + Surname[0];
            else
                return Username[0];
        }
    },
}

</script>

<style>
.user-profile {
    background: #060709;
    border-radius: 32px;
}

.user-profile .icon {
    background-color: #0075DF;
    border-radius: 200px;
    width: 24px;
    height: 24px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.user-profile .icon span {
    font-size: 11pt;
}

.user-profile .progress-bar .value {
    background: #112838;
}
</style>