<template>
  <div id="app" class="app">
    <Navbar />
    <main class="content">
      <Utilities />

      <router-view />
    </main>
  </div>
</template>

<script>
import "./assets/css/style.css";
import "./assets/css/hours-list.css";
import "./assets/css/main.css";
import "./assets/css/form.css";
import Navbar from "./components/Navbar.vue";
import Utilities from "./components/Utilities.vue";
import axios from "axios";
import router from "./router";
import { HOSTNAME } from "./api/requests";

export default {
  name: "app",
  components: {
    Navbar,
    Utilities,
  },

  data() {
    return {
      listClients: null,
      activeClient: null,


      activeEmployee: null,


      activeManager: null,


      state: {
        SearchContent: "",
        ActualDate: new Date(),
        User: {
          Name: String,
          Surname: String,
          Username: String,
          Profile: String,
          IsAdmin: Boolean,
        },
        UserSelected: "",
        ClientSelected: "",
        ClientSelectedData: {},
        HourSelected: {},
        TaskSelected: {},
        Client: {
          Open: false,
        },
        WorkSelected: {},
        report: 0,
        staffReport: 0,
        clientReport: 0,
        SelectedCommercial: -1,
        taskPanel: "done",
      },
    };
  },

  mounted() {
    this.validate();
  },

  watch: {
    $route: function () {
      this.validate();
    },
  },

  methods: {
    check: function (token) {
      let res = axios.get(HOSTNAME + "/api/auth/check/", {
        headers: {
          Token: token,
        },
      });
      return res;
    },

    redirect: function (IsAdmin) {
      router.push("dashboard" + (IsAdmin ? "-admin" : ""));

      //if(IsAdmin) router.push("fornitori");
    },

    validate: function () {
      let token = sessionStorage.getItem("token");

      if (token !== undefined && token !== null) {
        this.check(token).then((res) => {
          if (res.data.IsValid) {
            this.state.User.Name = res.data.Name;
            this.state.User.Surname = res.data.Surname;
            this.state.User.Username = res.data.Username;
            this.state.User.Profile = res.data.Profile;
            this.state.User.IsAdmin = res.data.IsAdmin;

            if (this.$route.path === "/") this.redirect(res.data.IsAdmin);
          } else {
            router.push("/");
          }
        });
      } else {
        router.push("/");
      }
    },


    setEmployee(employeeID = null) {

    },

    setClient() {

    },

    fetchClients: async function () {
      axios
        .get(HOSTNAME + ("/api/clients/all/"), {
          headers: {
            Token: sessionStorage.getItem("token"),
            Commercial: this.$root.state.SelectedCommercial,
          },
        })
        .then((res) => {
          this.listClients = res.data;
        });
    },
  },
};
</script>

<style>
.app {
  display: flex;
  flex-direction: row;
}

main {
  display: flex;
  flex-direction: column;
}

main .data-space {
  height: 0;
  flex-grow: 1;
}
</style>
